<template>
  <div class="coupon-exchange-setting">
    <PageTitle title="會員卡面設定" btn="新增自定義範本" btn2="啟用卡面範本" @btnClick="onCreate" @btn2Click="modal.enableMemberCard = true" />
    <MemberCardTable
      v-loading="loading.table"
      :configData="configData"
      :tableData="tableData"
      @refresh="refresh(false)"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />

    <EnableMemberCardModal
      v-if="modal.enableMemberCard"
      :configData="configData"
      :tableData="tableData"
      @close="modal.enableMemberCard = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import MemberCardTable from './components/MemberCardTable.vue'
import EnableMemberCardModal from './components/EnableMemberCardModal.vue'

import { systemMemberCardTemplate } from '@/config/memberCard'
import { GetMemberConfig } from '@/api/memberConfig'
import { GetCustomCardTemplate, GetCustomCardTemplateCount } from '@/api/memberCard'
import { useFetch } from '@/use/fetch'

export default defineComponent({
  name: 'MemberCardEdit',
  components: {
    PageTitle,
    MemberCardTable,
    EnableMemberCardModal,
  },
  setup (props) {
    const router = useRouter()
    const { simpleFetch } = useFetch()
    const modal = reactive({
      enableMemberCard: false,
    })
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    const configData = ref({})

    const onCreate = () => {
      router.push({ name: 'MemberCardEdit' })
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.allSettled([
        simpleFetch(GetMemberConfig, payload, (res) => { configData.value = res }),
        fetchData(GetCustomCardTemplate, payload),
        fetchDataCount(GetCustomCardTemplateCount, payload),
      ])

      tableData.value.unshift(...systemMemberCardTemplate)
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    return {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      onCreate,
      refresh,
      modal,
      configData,
    }
  },
})
</script>
