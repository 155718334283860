<template>
  <BaseDialog
    :show="true"
    title="啟用卡面範本"
    :btn1Loading="loading"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div>
      <el-form ref="formRef" :model="formData" :rules="formRules">
        <el-form-item label="選擇卡面範本" prop="enableTemplate">
          <el-select v-model="formData.enableTemplate" value-key="id">
            <el-option
              v-for="option in templateOptions"
              :key="option.id"
              :label="option.label"
              :value="option"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { mappingSyncFormData, useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { systemMemberCardStyles } from '@/config/memberCard'
import { map, get, find } from 'lodash'
import { UpdateMemberConfig } from '@/api/memberConfig'
import { useShop } from '@/use/shop'
import { useFetch } from '@/use/fetch'

export default defineComponent({
  name: 'EnableMemberCardModal',
  components: { BaseDialog },
  props: {
    configData: { type: Object, default: () => ({}) },
    tableData: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const { formRef, initFormData, formData, loading, onSubmit } = useBaseForm()
    const { shopId } = useShop()
    const { simpleFetch } = useFetch()

    initFormData({
      enableTemplate: null,
    })

    const formRules = computed(() => {
      const rules = {
        enableTemplate: [noEmptyRules()],
      }
      return rules
    })

    const templateOptions = computed(() => {
      return map(props.tableData, (item) => {
        const isSystemStyle = item.id.includes('@system-')
        return {
          label: item.displayName || item.name,
          value: isSystemStyle ? item.name : 'custom',
          id: item.id,
        }
      })
    })

    const curCardStyle = computed(() => {
      const styleName = get(props.configData, 'cardStyle')
      if (styleName === 'custom') return get(props.configData, 'CustomCardTemplateId')
      return `@system-${styleName}`
    })

    const onConfirm = async () => {
      await onSubmit(async (data) => {
        const styleId = get(data.enableTemplate, 'id')
        const isSystemStyle = styleId.includes('@system-')

        await simpleFetch(UpdateMemberConfig, {
          shopId: shopId.value,
          cardStyle: get(data.enableTemplate, 'value'),
          CustomCardTemplateId: isSystemStyle ? undefined : styleId,
        }, (res) => {
          window.$message.success('更新成功！')
          emit('refresh')
          emit('close')
        })
      }, formData)
    }

    onMounted(async () => {
      const res = find(props.tableData, { id: curCardStyle.value })
      const isSystemStyle = res.id.includes('@system-')
      formData.enableTemplate = {
        label: res.displayName || res.name,
        value: isSystemStyle ? res.name : 'custom',
        id: res.id,
      }
    })

    return { onConfirm, formRef, initFormData, formData, loading, formRules, templateOptions, curCardStyle }
  },
})
</script>

<style scoped lang="postcss">
</style>
